import React from 'react';
import discord_logo from "../../assets/Images/icon.png";
import style from "../../assets/styles/home_style.css";

const Nav = () => {
    return(
        <nav>
            <div className="reload-home">
                <img id="home-nav-img" src={discord_logo}></img>
                <span>Meepoz</span>
            </div>
            <div className="infos">
                <a href='/download' className='nav-div-a'>Download</a>
                <a href='/features' className='nav-div-a'>Features</a>
                <a href='/about' className='nav-div-a'>About</a>
                <a href='/support' className='nav-div-a'>Support</a>
                <a href='/careers' className='nav-div-a'>Careers</a>
            </div>
            <div>
                <button className='login-btn'>
                    <a href='/login'>Login</a>
                </button>
                <a className='nav-div-a'></a>
                <button className='login-btn'>
                    <a href='/register'>Register</a>
                </button>
            </div>
        </nav>
    )
}

export default Nav;