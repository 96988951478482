import style from "../../assets/styles/home_style.css";
const HomeBody = () => {
    return(
        <div id="home-body">
            <h2 id="home-body-heading"> Happy Tropical Week...</h2>
            <p className="home-body-span">...What's Tropical Week?</p>
            <p className="home-body-span">Tropical Week is a Monthly or Yearly thing. Annually before major holidays, for honoring our staff team.</p>
            <p className="home-body-span"> 🍍 𝗪𝗼𝗿𝗸𝗶𝗻𝗴 𝗶𝗻 𝗣𝗿𝗼𝗴𝗿𝗲𝘀𝘀 Currently 𝘃𝟯.𝟭.𝟬 </p>
       
        </div>
    )
}

export default HomeBody;