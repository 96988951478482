import React, { Component } from "react";
import style from "../assets/styles/careers_style.css";
 
class Careers extends Component {
   render () {
       return(
 
        
        <div className="App">
      <header className="App-header">
        <p>
        <h1>Meepoz Careers</h1>
<p>Want a Career at meepoz? Check out </p>
        </p>
        <a
          className="App-link"
          href="http://localhost:3000/" target="_self"> Click me! To go back to the home page!</a>
      </header>
    </div>
          
 
       )
   }
}
 
export default Careers

