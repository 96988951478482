import React, { Component } from "react";
import style from "../assets/styles/features_style.css";
 
class Features extends Component {
   render () {
       return(
 
        
        <div className="App">
      <header className="App-header">
 
      <div id="">
            <h2 id="home-body-heading">👻 Meepoz Features... 🎃</h2>
            <p className="home-body-span">Our login is under development and sign up!</p>
            <p className="home-body-span"></p>
            
        </div>
        <p>
           <code></code> 
          
        </p>
        <p>
         <code></code> 
        </p>
        <p>
          <code></code> 
        </p>
        <a
          className="App-link"
          href="http://localhost:3000/" target="_self"> Click me! To go back to the home page!</a>
      </header>
    </div>
          
 
       )
   }
}
 
export default Features

