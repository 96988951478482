import bg_img from "../../assets/Images/dashboard_bg.svg";

const DashboardMain = () => {
    return(
        <div id="dashboard-main-main">
            <img src={bg_img} />
            <p>No one's around to play with Wumpus</p>
        </div>
    )
}

export default DashboardMain;