import React, { Component } from "react";
import style from "../assets/styles/about_style.css";
 
class About extends Component {
   render () {
       return(
 
        
        <div className="App">
      <header className="App-header">
      <h2 id="home-body-heading">🤠 About Meepoz 🤡</h2>
            <p className="home-body-span"></p>
            <p className="home-body-span"></p>
        <a
          className="App-link"
          href="http://localhost:3000/" target="_self"> Click me! To go back to the home page!</a>
      </header>
    </div>
          
 
       )
   }
}
 
export default About

