import React, { Component } from "react";
import style from "../assets/styles/download_style.css";
 
class Download extends Component {
   render () {
       return(
 
        
        <div className="App">
      <header className="App-header">


      <div id="home-body">
            <h2 id="home-body-heading">📱 iPhones & Androids... 📱</h2>
            <p className="home-body-span">...our community devlopers have not came out with a app yet.</p>
            <p className="home-body-span">our app won't be ready for a bit but when it is it will be on our home page!</p>
            
        </div>
        <p>
           <code></code> 
          
        </p>
        <p>
        Meepoz App for iPhones <code></code> Coming Soon!
        </p>
        <p>
         Meepoz App for Androids <code></code> Coming Soon!
        </p>
        <a
          className="App-link"
          href="http://localhost:3000/" target="_self"> Click me! To go back to the home page!</a>
      </header>
    </div>
          
 
       )
   }
}
 
export default Download

