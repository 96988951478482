import download_icon from "../../assets/Images/download_icon.png";

const HomeBtns = () => {
    return(
        <div id="home-btns-div">
            <div className="home-btns-sub-div">
                <button id="download-mac-btn">
                <img src={download_icon}></img>
                <a href='/download'>
             
                     Download for iPhones
                     
                     </a>
                </button>
            </div>

            <div className="home-btns-sub-div">
                <button id="open-in-browser-btn">
                    <a href="/login">
                        Open Meepoz in your browser

                    </a>
                </button>
            </div>
        </div>
    )   
}

export default HomeBtns;

// <img src={download_icon}></img>