import React, { Component } from "react";
import style from "../assets/styles/support_style.css";
 
class Support extends Component {
   render () {
       return(
 
        
        <div className="App">
      <header className="App-header">

            <h2 id="home-body-heading"> 🖥️ Need support?... 🖥️</h2>
            <p className="home-body-span">...our community team members have not came out with a app yet.</p>
            <p className="home-body-span">our app won't be ready for a bit but when it is it will be on our home page!</p>

        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="http://localhost:3000/" target="_self"> Click me! To go back to the home page!</a>
      </header>
    </div>
          
 
       )
   }
}
 
export default Support

